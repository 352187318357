import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HistoryLine from "../HistoryLine";
import URLS from "../../../../api/url.js";
import { findSubcaste } from "../../../utilities/subcaste.js";
import { encryptMessage } from "../../../utilities/CypherMessage.jsx";
import { resetUserData } from "../../../utilities/Reset";

const AddChildForm = ({
	casteId,
	currentUser,
	isAdmin,
	lang,
	parentHierarchy,
	people,
	person,
	onPeopleLoaded,
}) => {
	let navigate = useNavigate();

	const [firstname, setFirstname] = useState("");
	const [firstnameNepali, setFirstnameNepali] = useState("");
	const [gender, setGender] = useState("male");
	const [order, setOrder] = useState(0);
	const [isFemale, setIsFemale] = useState(false);
	const [isWife, setIsWife] = useState(false);
	const [femaleSurname, setFemaleSurname] = useState("");
	const [addLabel, setAddLabel] = useState("Child");

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChildNameChange = (event) => {
		const val = event.target.value;
		setFirstname(val);
	};

	const handleChildNepaliNameChange = (event) => {
		const val = event.target.value;
		setFirstnameNepali(val);
	};

	const handleGenderChange = (e) => {
		const val = e.target.value;

		if (val === "female") {
			setIsFemale(true);
		} else {
			setIsFemale(false);
		}

		setGender(val);
	};

	const handleOrderChange = (event) => {
		const val = event.target.value;
		setOrder(val);
	};

	const handleIsWife = () => {
		let opposite = !isWife;
		setIsWife(opposite);
		if (opposite === true) {
			setAddLabel("Wife");
		} else {
			setAddLabel("Child");
		}
	};

	const handleFemaleSurname = (event) => {
		const val = event.target.value;
		setFemaleSurname(val);
	};

	const reset = () => {
		setFirstname("");
		setFirstnameNepali("");
		setGender("male");
		setOrder(0);
		setIsWife(false);
		setFemaleSurname("");
		setIsSubmitting(false);
	};

	const handleSaveChild = (e) => {
		const url = URLS.base_url + URLS.person.base + URLS.person.addChild;
		const token = currentUser.accessToken;
		setIsSubmitting(true);

		if (casteId === undefined) {
			alert("Caste ID is missing");
			setIsSubmitting(false);
			return;
		}

		const selectedSubcaste = findSubcaste(casteId);

		if (token === null) return [];

		const headers = {
			headers: { authorization: `Bearer ${token}` },
		};

		let currentSurname =
			selectedSubcaste !== undefined ? selectedSubcaste.name : "";
		if (gender === "female" && femaleSurname.length) {
			currentSurname = femaleSurname;
		}

		let sendData = {
			casteId: casteId,
			fatherId: person.id,
			firstname,
			firstnameNepali,
			gender,
			generation: parseInt(person.generation) + 1,
			order: parseInt(order),
			isFemale: isFemale,
			isWife: isWife,
			subcasteId: casteId,
			surname: currentSurname,
			uid: localStorage.getItem("userId"),
		};

		// validation
		let errors = [];
		if (sendData.firstname.length <= 0) {
			errors.push("firstname is required");
		}
		if (sendData.firstnameNepali.length <= 0) {
			errors.push("firstname in Nepali is required");
		}

		if (errors.length) {
			alert("error : " + errors.join(", "));
			setIsSubmitting(false);
			return false;
		}

		axios
			.post(url, sendData, headers)
			.then((res) => {
				const result = res.data;
				if (result.success) {
					// window.location.reload();

					// add new child to people state
					let newPerson = result.person;
					let newPeople = [...people];
					newPeople.push(newPerson);

					// store new list of people
					let encryptedPeople = encryptMessage(newPeople);
					localStorage.setItem("people-" + casteId, encryptedPeople);

					// reset form
					reset();

					// tell parent people were reloaded
					onPeopleLoaded(newPeople);
				}

				setIsSubmitting(false);
			})
			.catch((error) => {
				if (error.code === "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					// reset data
					// resetUserData(navigate, "/login?expired=true");
				}
				setIsSubmitting(false);
			});
	};

	return (
		<div className="form-wrapper-children">
			{isAdmin && currentUser && (
				<div className="add-child-form">
					<p className="add-child-header">Add {addLabel}</p>
					<p></p>
					<p>Firstname</p>
					<div className="form-item">
						<input
							onChange={handleChildNameChange}
							placeholder="firstname"
						/>
					</div>

					<p>Firstname nepali</p>
					<div className="form-item">
						<input
							onChange={handleChildNepaliNameChange}
							placeholder="firstname nepali"
						/>
					</div>

					<p>Gender</p>
					<div className="form-item form-item-gender">
						<label>
							<input
								type="radio"
								name="gender"
								value="male"
								checked={gender === "male"}
								onChange={handleGenderChange}
							/>
							<span>Male</span>
						</label>

						<label>
							<input
								type="radio"
								name="gender"
								value="female"
								checked={gender === "female"}
								onChange={handleGenderChange}
							/>
							<span>Female</span>
						</label>
					</div>

					<p>Order</p>
					<div className="form-item">
						<input
							onChange={handleOrderChange}
							type="number"
							min="1"
							placeholder="order"
						/>
					</div>

					{isFemale && (
						<>
							<p>Is spouse / wife?</p>
							<div className="form-item form-checkbox">
								<label>
									<input
										type="checkbox"
										name="isWife"
										value="1"
										checked={isWife}
										onChange={handleIsWife}
									/>
								</label>
							</div>

							<p>Surname</p>
							<div className="form-item form-item-surname">
								<input
									value={femaleSurname}
									onChange={handleFemaleSurname}
									placeholder="female surname"
								/>
							</div>
						</>
					)}

					<button
						onClick={handleSaveChild}
						className="btn-add-child"
						disabled={isSubmitting}
					>
						{isSubmitting && (
							<span className="loading-spinner"></span>
						)}
						<span>Add {addLabel}</span>
					</button>
				</div>
			)}

			{!isAdmin && parentHierarchy.length > 0 && (
				<HistoryLine parents={parentHierarchy} lang={lang} />
			)}

			{!isAdmin && person && (
				<p id="not-allowed">{/* {person.firstname} */}</p>
			)}
		</div>
	);
};

export default AddChildForm;
