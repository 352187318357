import CryptoJS from "crypto-js";

const LACA = process.env.REACT_APP_LACA;

/**
 * Decrypt a message
 *
 * @param {*} text
 * @returns
 */
export const cypherMessage = (text) => {
	if (text === null) return;

	const byaa = CryptoJS.AES.decrypt(text, LACA);
	const result = byaa.toString(CryptoJS.enc.Utf8);

	return result;
};

/**
 * Encrypts a message
 * @param {*} text
 * @returns
 */
export const encryptMessage = (text) => {
	if (text === null) return;

	if (typeof text !== "string") {
		text = JSON.stringify(text);
	}

	const byaa = CryptoJS.AES.encrypt(text, LACA);

	return byaa.toString();
};
