import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import URLS from "../../../../api/url.js";
import { encryptMessage } from "../../../utilities/CypherMessage.jsx";

const DeleteButton = ({
	casteId,
	currentUser,
	people,
	person,
	onDeleteCompleted,
}) => {
	let navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const token = currentUser.accessToken;

	const handleDeletePerson = () => {
		if (!person) return;

		if (!window.confirm("Are you sure you want to delete this person?")) {
			return;
		}

		setIsSubmitting(true);

		const url = URLS.base_url + URLS.person.base + URLS.person.remove;

		let dataToSend = {
			uid: person.id,
			person: person,
			subcasteId: casteId,
		};

		axios
			.delete(url, {
				headers: { authorization: `Bearer ${token}` },
				data: dataToSend,
			})
			.then((res) => {
				if (res.data.success) {
					// remove person from the people list
					let newPeople = people.filter((p) => p.id !== person.id);

					// store new list of people
					let encryptedPeople = encryptMessage(newPeople);
					localStorage.setItem("people-" + casteId, encryptedPeople);

					onDeleteCompleted(newPeople);

					setIsSubmitting(false);
				}
			})
			.catch((error) => {
				console.log(error);
				if (error.code === "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					navigate("/login?expired=true");
				}
				setIsSubmitting(false);
			});
	};

	//TODO check user permission for allowing delete operation
	// return null;

	return (
		<button
			className="btn btn-delete"
			onClick={handleDeletePerson}
			disabled={isSubmitting}
		>
			{isSubmitting && <span className="loading-spinner"></span>}
			<span>Delete</span>
		</button>
	);
};

export default DeleteButton;
