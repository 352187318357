import { Link } from "react-router-dom";

import images from "../../api/imagesConfig.js";
import { useUser } from "../utilities/UserContext.jsx";

const Nav = ({ onClick }) => {
	let burgerMenu = images.icons["menu"];

	const { currentUser, loading } = useUser();

	const notExpired = currentUser && !currentUser.expired;

	const showNavMenuMobile = () => {
		onClick();
	};

	return (
		<div>
			<div className="nav-wrapper">
				<nav>
					<li>
						<Link to="/caste/select">Search</Link>
					</li>
					<li>
						<Link to="/features">Features</Link>
					</li>
					<li>
						<Link to="/faq">FAQ</Link>
					</li>
					{!loading && (
						<>
							<li>
								{currentUser || notExpired ? (
									<Link to="/login">Account</Link>
								) : (
									<Link to="/login">Sign In</Link>
								)}
							</li>
						</>
					)}
					<li className="donate-button">
						<Link to="/donate">Donate</Link>
					</li>
				</nav>

				<div className="burger-menu">
					<img
						src={burgerMenu}
						alt="mobile-menu"
						onClick={showNavMenuMobile}
					/>
				</div>
			</div>
		</div>
	);
};

export default Nav;
