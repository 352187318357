import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaRegBookmark } from "react-icons/fa";
import URLS from "../../../../api/url.js";

const BookmarkButton = ({
	casteId,
	currentUser,
	person,
	onBookmarkCompleted,
}) => {
	let navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);

	// save current 'person' as myself
	const handleBookmarkClick = () => {
		if (!person || isSubmitting) return;

		setIsSubmitting(true);

		const url = URLS.base_url + URLS.people.base + URLS.people.person;
		const token = localStorage.getItem("accessToken");

		if (token === null) return [];

		const headers = {
			headers: { authorization: `Bearer ${token}` },
		};

		let sendData = {
			casteId: casteId,
			person: person,
			uid: localStorage.getItem("userId"),
		};

		axios
			.post(url, sendData, headers)
			.then((res) => {
				const result = res.data;
				if (result.success) {
				} else {
				}
				setIsSubmitting(false);
				onBookmarkCompleted(person);
			})
			.catch((error) => {
				if (error.code === "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					navigate("/login?expired=true");
				}
				setIsSubmitting(false);
			});
	};

	return (
		<button
			type="button"
			className="btn btn-bookmark"
			onClick={handleBookmarkClick}
			disabled={isSubmitting}
		>
			{isSubmitting ? (
				<span className="loading-spinner"></span>
			) : (
				<FaRegBookmark style={{ fontSize: 28 }} />
			)}
		</button>
	);
};

export default BookmarkButton;
