import { getUserInfo } from "../api/UserInfo";
import { useEffect, useState } from "react";
import { buildTreeUrl } from "../api/UserInfo";

import { cypherMessage, encryptMessage } from "../utilities/CypherMessage.jsx";

const LoginTreeLink = ({ user, onSessionExpired }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [link, setLink] = useState("");

	const getUserLink = async () => {
		if (!user) return;

		let userInfo = null; // if user hasn't bookarked himself, this will remain null

		let tempData = localStorage.getItem("temp");
		if (tempData) {
			tempData = cypherMessage(tempData);
			userInfo = JSON.parse(tempData);
			userInfo.treeUrl = buildTreeUrl(userInfo);
			setLink(userInfo.treeUrl);
		} else {
			userInfo = await getUserInfo(user.uid, user.accessToken);
			userInfo.treeUrl = buildTreeUrl(userInfo);
			setLink(userInfo.treeUrl);
			userInfo = encryptMessage(JSON.stringify(userInfo));
			localStorage.setItem("temp", userInfo);
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getUserLink();
	}, [user]);

	return (
		<>
			{isLoading ? (
				<>loading...</>
			) : (
				<SmallLink show={link.length} url={link} />
			)}
		</>
	);
};

const SmallLink = ({ show, url }) => {
	return (
		<>
			{show ? (
				<a className="btn" href={url}>
					Open My Tree
				</a>
			) : (
				<></>
			)}
		</>
	);
};

export default LoginTreeLink;
