import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../../api/firebase";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				// const idToken = await user.getIdToken();
				const decodedToken = await user.getIdTokenResult();

				// add expired property
				user.expired = isTokenExpired(decodedToken.expirationTime);

				setCurrentUser(user);
			}
			setLoading(false);
		});

		return () => unsubscribe();
	}, []);

	const logout = async () => {
		await auth.signOut();
		setCurrentUser(null);
		localStorage.removeItem("yourDataKey"); // Clear any additional data
	};

	const isTokenExpired = (expirationTime) => {
		const expirationDate = new Date(expirationTime);
		const currentDate = new Date();
		return expirationDate < currentDate;
	};

	return (
		<UserContext.Provider value={{ currentUser, loading, logout }}>
			{children}
		</UserContext.Provider>
	);
};
