import React from "react";

/**
 * HistoryLine component
 *  - This component is used to display the history line of a person
 *  - Uses array of parents to display the history line
 *  - List of person's father, father's father, father's father's father, and so on
 *
 */

function HistoryLine({ parents, lang }) {
	const handleClick = (parent) => {
		// reload page with focusing on the parent as person
		const url =
			"tree?id=" + parent.id + "&caste=" + parent.caste + "&lang=" + lang;
		window.location.href = url;
	};

	const renderElements = parents.map((parent, index) => {
		const className = index % 2 ? "even" : "odd";
		return (
			<div
				key={index}
				className={`history-item ${className}`}
				onClick={() => handleClick(parent)}
			>
				{lang === "nepali" ? (
					<p>
						{parent.generation}. {parent.firstname_nepali}
					</p>
				) : (
					<p>
						{parent.generation}. {parent.firstname}
					</p>
				)}
			</div>
		);
	});

	return (
		<div className="history-wrapper">
			<h3>History line</h3>
			<p className="info">
				(click on person to go to that point in family tree)
			</p>
			<div className="content">{renderElements}</div>
		</div>
	);
}

export default HistoryLine;
