import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import Cookies from "js-cookie";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import LoginTreeLink from "../login/LoginTreeLink";
import { resetUserData } from "../utilities/Reset";
import { useUser } from "../utilities/UserContext";
import LoginForm from "./Login/LoginForm.jsx";
import { auth } from "../../api/firebase";

const LoginPage = () => {
	const { currentUser, logout, loading } = useUser();
	const [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();

	const redirectPath = searchParams.get("redirect"); // null or string
	const isExpiredRedirect = searchParams.get("expired"); // null or string
	const [user] = useAuthState(auth);

	const [welcomeMessage, setWelcomeMessage] = useState("");
	const [infoMessage, setInfoMessage] = useState(
		"You must be signed in to view this page"
	);

	const [userDisplayName, setUserDisplayName] = useState(
		localStorage.getItem("userDisplayName")
			? localStorage.getItem("userDisplayName")
			: ""
	);

	const [myPhoto, setMyPhoto] = useState(
		localStorage.getItem("photoUrl")
			? localStorage.getItem("photoUrl")
			: null
	);

	const handleLoginSuccess = (data) => {
		if (data === undefined) return;

		setUserDisplayName(data.displayName);
		setMyPhoto(data.photoUrl);

		if (redirectPath) {
			navigate(`/${redirectPath}`);
		}
	};

	const handleLogout = () => {
		logout();
		resetUserData(navigate);
	};

	useEffect(() => {
		let message = "Welcome";
		if (userDisplayName !== null && userDisplayName !== "null")
			message += `, ${userDisplayName}`;
		setWelcomeMessage(message);
	}, [user, userDisplayName]);

	useEffect(() => {
		if (loading === false) {
			if (currentUser !== null && redirectPath) {
				if (redirectPath === "access") {
					setInfoMessage("Your session has expired. Please sing in.");
					console.log("access token expired from server");
					handleLogout();
				} else {
					navigate(`/${redirectPath}`);
				}
			}
		}
	}, [loading]);

	useEffect(() => {
		if (isExpiredRedirect) {
			// user token has expired, so it has been redirected to this page
			handleLogout();
		}
	}, []);

	return (
		<div className="page login-page">
			<Header />

			{redirectPath && currentUser === null && (
				<div className="info-box">{infoMessage}</div>
			)}

			{loading ? (
				<div className="spinner-wrapper">
					<div className="spinner"></div>
				</div>
			) : (
				<>
					{currentUser ? (
						<>
							<h1>{welcomeMessage}</h1>
							<div className="block-wrapper">
								<div className="block">
									{myPhoto ? (
										<img
											src={myPhoto}
											alt="my-profile-picture"
										/>
									) : (
										<></>
									)}

									<p>
										<span>Email:</span> {currentUser?.email}
									</p>

									<LoginTreeLink
										user={currentUser}
										onSessionExpired={handleLogout}
									/>

									<div className="account-actions">
										<button onClick={handleLogout}>
											Logout
										</button>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<LoginForm
								onLoginSuccess={handleLoginSuccess}
								redirectPath={redirectPath}
							/>
						</>
					)}
				</>
			)}

			<Footer />
		</div>
	);
};

export default LoginPage;
