import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./scss/main.scss";

import HomePage from "./components/pages/HomePage";
import TreePage from "./components/pages/TreePage";
import SearchPage from "./components/pages/SearchPage";
import CasteSelectPage from "./components/pages/CasteSelectPage";
import LoginPage from "./components/pages/LoginPage";
import Register from "./components/pages/Register";
import Reset from "./components/pages/Reset";
import Maintenance from "./components/pages/Maintenance";
import AboutPage from "./components/pages/AboutPage";
import DonatePage from "./components/pages/DonatePage";
import CancelPage from "./components/pages/Payment/CancelPage";
import SuccessPage from "./components/pages/Payment/SuccessPage";
import FaqPage from "./components/pages/FaqPage";
import SuggestionPage from "./components/pages/SuggestionPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import TreeSamplePage from "./components/pages/TreeSamplePage";
import Suggestions from "./components/pages/Suggestion/Suggestions";
import UserRoles from "./components/pages/Admin/UserRoles";

import { UserProvider } from "./components/utilities/UserContext";
import FeaturesPage from "./components/pages/FeaturesPage";
import SingleTree from "./components/pages/Tree/SingleTree/SingleTree";

// Create a client
// const queryClient = new QueryClient();

function App() {
	return (
		<UserProvider>
			<Router>
				<Routes>
					<Route path="/" element={<HomePage />} />

					<Route path="/about" element={<AboutPage />} />

					<Route path="/caste/select" element={<CasteSelectPage />} />

					<Route path="/donate" element={<DonatePage />} />

					<Route path="/donate/cancel" element={<CancelPage />} />

					<Route path="/donate/success" element={<SuccessPage />} />

					<Route path="/faq" element={<FaqPage />} />

					<Route path="/features" element={<FeaturesPage />} />

					<Route path="/login" element={<LoginPage />} />

					<Route path="/maintenance" element={<Maintenance />} />

					<Route path="/register" element={<Register />} />

					<Route path="/reset" element={<Reset />} />

					<Route path="/search" element={<SearchPage />} />

					<Route path="/single-tree" element={<SingleTree />} />

					<Route path="/admin/user-roles" element={<UserRoles />} />

					<Route path="/suggestions" element={<SuggestionPage />} />

					<Route path="/suggestions/list" element={<Suggestions />} />

					<Route path="/tree" element={<TreePage />} />

					<Route path="/sample" element={<TreeSamplePage />} />

					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</Router>
		</UserProvider>
	);
}

export default App;
