import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import {
	logInWithEmailAndPassword,
	signInWithGoogle,
} from "../../../api/firebase";

import images from "../../../api/imagesConfig.js";

const LoginForm = ({ onLoginSuccess }) => {
	const [errorMessage, setErrorMessage] = useState("");

	let googleImage = images.icons["google"];

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const cookiesAccepted = Cookies.get("nama-cookie-accepted");
	const [rememberMeIsChecked, setRememberMeIsChecked] = useState(false);

	const handleUserEmailChange = (e) => {
		setErrorMessage("");
		setEmail(e.target.value);
	};

	const handleUserPassChange = (e) => {
		setErrorMessage("");
		setPassword(e.target.value);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			handleEmailSignIn();
		}
	};

	const handleEmailSignIn = async () => {
		if (email.length === 0) {
			setErrorMessage("Email is required.");
			return;
		}
		if (password.length === 0) {
			setErrorMessage("Password is required.");
			return;
		}

		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		if (!emailPattern.test(email)) {
			setErrorMessage("Invalid email address.");
			return;
		}

		const result = await logInWithEmailAndPassword(email, password);
		if (result.length) {
			setErrorMessage("Invalid email or password.");
		}

		const tempAccessToken = localStorage.getItem("accessToken");
		const tempUserDisplayName = localStorage.getItem("userDisplayName");
		const tempUserEmail = localStorage.getItem("userEmail");
		const tempPhotoUrl = localStorage.getItem("photoUrl");
		const tempUserId = localStorage.getItem("userId");
		if (tempAccessToken) {
			onLoginSuccess({
				displayName: tempUserDisplayName,
				userEmail: tempUserEmail,
				photoUrl: tempPhotoUrl,
				userId: tempUserId,
			});
		}
	};

	const handleSignInWithGoogle = async () => {
		const result = await signInWithGoogle();
		if (result.length) {
			if (result === "Missing or insufficient permissions.") {
				setErrorMessage("Failed to login with google");
			} else {
				setErrorMessage(result);
			}
		}

		const tempAccessToken = localStorage.getItem("accessToken");
		const tempUserDisplayName = localStorage.getItem("userDisplayName");
		const tempUserEmail = localStorage.getItem("userEmail");
		const tempPhotoUrl = localStorage.getItem("photoUrl");
		const tempUserId = localStorage.getItem("userId");
		if (tempAccessToken) {
			onLoginSuccess({
				displayName: tempUserDisplayName,
				userEmail: tempUserEmail,
				photoUrl: tempPhotoUrl,
				userId: tempUserId,
			});
		}
	};

	useEffect(() => {
		if (cookiesAccepted) {
			const cookieRememberMe = Cookies.get("nama-remember-me");
			setRememberMeIsChecked(cookieRememberMe === "true" ? true : false);
			const cookieEmail = Cookies.get("nama-remember-email");
			if (cookieEmail !== undefined) setEmail(cookieEmail);
		}
	}, []);

	return (
		<div className="block-wrapper">
			<div className="login-container block">
				<div className="login-content">
					<h2>Sign In</h2>
					<input
						type="text"
						className="login__textBox"
						value={email}
						onChange={handleUserEmailChange}
						placeholder="E-mail"
					/>
					<input
						type="password"
						className="login__textBox"
						value={password}
						onChange={handleUserPassChange}
						onKeyPress={handleKeyPress}
						placeholder="Password"
					/>

					{errorMessage.length ? (
						<div className="error-message">{errorMessage}</div>
					) : (
						<></>
					)}

					<button className="login__btn" onClick={handleEmailSignIn}>
						Login
					</button>

					<div className="remember-me">
						<input
							type="checkbox"
							id="remember-me"
							checked={rememberMeIsChecked}
							onChange={() => {
								if (cookiesAccepted) {
									const newValue = !rememberMeIsChecked;
									setRememberMeIsChecked(newValue);
									Cookies.set("nama-remember-me", newValue, {
										expires: 7,
									});
									if (newValue) {
										Cookies.set(
											"nama-remember-email",
											email,
											{ expires: 7 }
										);
									} else {
										Cookies.remove("nama-remember-email");
										setEmail("");
									}
								}
							}}
						/>
						<label htmlFor="remember-me">Remember me</label>
					</div>

					<div className="login-extra">
						<Link to="/register">Create account</Link>
						<Link to="/reset">Forgot Password</Link>
					</div>

					<div className="or-divider"></div>

					<button
						className="login__btn login__google"
						onClick={handleSignInWithGoogle}
					>
						<img src={googleImage} alt="google sign in" />
						<p>Sign in with Google</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default LoginForm;
